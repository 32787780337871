import {
  Typography,
  Link,
  ActionBar
} from '@org-crowley/enterprise-react-component-library';
import { withAuth } from '../components/withAuth';
import type { NextPage } from 'next';

const Index: NextPage = () => {
  return (
    <main className="p-3 overflow-y-auto">
      <div className="flex justify-around flex-col max-w-lg mx-auto 2xl:flex-row 2xl:max-w-full 2xl:m-0">
        <div className="max-w-screen-sm">
          <Typography variant="h600" as="p">
            Welcome to the React Starter
          </Typography>
          <Typography className="text-silver-90" as="div">
            This is the{' '}
            <span className="font-bold">Crowley Starter Application</span>.
            It&#39;s built from our{' '}
            <span className="font-bold">Web Platform</span> and includes all the
            necessary building blocks for enterprise app development including:
            <ul className="ml-6 list-disc">
              <li>
                <span className="font-bold">React:</span> one of the most widely
                used libraries for building Web Applications
              </li>
              <li>
                <span className="font-bold">TailwindCSS:</span> for easily
                customizing the User Interface
              </li>
              <li>
                <span className="font-bold">Enterprise Component Library:</span>{' '}
                commonly used re-usable components custom tailored to match
                Crowely&#39;s new brand
              </li>
              <li>
                <span className="font-bold">Okta:</span> single sign-on security
                framework used to secure all applications at Crowley
              </li>
              <li>
                <span className="font-bold">Testing Suite:</span> executed at
                application build-time to catch errors before they&#39;re
                deployed into production
              </li>
              <li>
                <span className="font-bold">Continuous Delivery:</span> an
                automated pipeline to build and deploy the application quickly
                through different stages of development
              </li>
            </ul>
            <p className="mt-2">
              Further Documentation can be located in&nbsp;
              <Link>
                <a
                  href="https://crowley.atlassian.net/wiki/spaces/DT/pages/17826407/Web+Platform"
                  target="_blank"
                  rel="noreferrer"
                >
                  Confluence
                </a>
              </Link>
              .
            </p>
          </Typography>
          <Typography variant="h500" className="mt-6" as="p">
            Enterprise Component Library
          </Typography>
          <Typography className="text-silver-90" as="p">
            The <span className="font-bold">Enterprise Component Library</span>{' '}
            is a key part of Enterprise Apps built from the{' '}
            <span className="font-bold">Crowley Starter Application</span>.
            It&#39;s versioned and provides a consistent way for apps to share
            common components, design elements, and the Crowley Brand Styling.
            Check out our&nbsp;
            <Link>
              <a
                href="https://crowley.atlassian.net/wiki/spaces/DT/pages/90276094/Component+Library"
                target="_blank"
                rel="noreferrer"
              >
                Component Library
              </a>
            </Link>
            &nbsp;page for more info and ways to contribute or feel free to
            reach out to the Digital Technology Team regarding any questions.
          </Typography>
        </div>
        <div className="flex flex-col pt-3 2xl:pt-0">
          <Typography variant="h500" className="text-center mb-2" as="p">
            Web Platform
          </Typography>
          <img
            src="web-dev-stack.png"
            alt="Diagram of the implemented technology"
            className="border"
            style={{ height: '500px' }}
          />
        </div>
        <div className="flex flex-col pt-3 2xl:pt-0">
          <Typography as="p" variant="h500" className="text-center mb-2">
            Component Library
          </Typography>
          <img
            src="storybook.png"
            alt="Image of Storybook"
            className="border"
            style={{ height: '500px' }}
          />
        </div>
      </div>
    </main>
  );
};

export default withAuth(Index);
